<template>
  <v-container>
    <v-card class="pa-0" :loading="description_loading">
      <v-card-title>Main Lobby</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
            <v-row>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta Title"
              v-model="meta_data.meta.title"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta description"
              v-model="meta_data.meta.description"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          label="Title"
          v-model="meta_data.title"
          outlined
          dense
        ></v-text-field>

        <!-- <p class="font-weight-bold">Main Lobby Description</p> -->
        <!-- <vue-editor v-model="meta_data.description"></vue-editor> -->
        <v-btn
          class="mr-4 mt-2"
          color="secondary"
          @click="updateMetaPage()"
          :loading="description_loading"
          :disabled="description_loading"
          >submit</v-btn
        >
      </v-card-text>
    </v-card>
      </v-container>
</template>
<script>
import { mapMutations } from "vuex";
// import { VueEditor } from "vue2-editor";
export default {
  name: "MainLobby",
  mounted() {
    this.getMetaPageDetails();
  },
  components: {
    // VueEditor,
  },
  data() {
    return {
      loading: false,
      description_loading: false,
      dialog: false,
      meta_data: {
        meta: {
          title: "",
          description: "",
        },
      }
     
    };
  },
 
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateMetaPage() {
      let _self = this;
      this.description_loading = true;
      this.$axios
        .put(`admin/metapage/edit/${this.meta_data.id}`, this.meta_data)
        .then((response) => {
          if (response.data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/main-lobby");
          }
          _self.description_loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.description_loading = false;
        });
    },
  
    getMetaPageDetails() {
      let _self = this;
      this.description_loading = true;
      this.$axios
        .get("/admin/metapages/MAINLOBBY")
        .then((response) => {
          _self.meta_data = response.data.data;
          var doc = new DOMParser().parseFromString(
            _self.meta_data.description,
            "text/html"
          );
          _self.meta_data.description = doc.documentElement.textContent;
          _self.description_loading = false;
        })
        .catch(function () {
          _self.description_loading = false;
        });
    }
  },
};
</script>